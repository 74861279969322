import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import pic08 from '../assets/images/boilerplate-code-nodejs-starter.png'
import pic09 from '../assets/images/boilerplate-code-laravel-boilerplate.png'
import pic10 from '../assets/images/boilerplate-code-flask-boilerplate.png'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Boilerplate code - Nodejs, Laravel and Flask starters</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h3>
                            <a href="https://nodejs.org/en/">Nodejs</a>, <a href="https://laravel.com/">Laravel</a> and <a href="https://palletsprojects.com/p/flask/">Flask</a> Boilerplates
                        </h3>
                    </header>
                    <p>
                        A short list with starter kits (boilerplates), coded in modern technologies, already equipped with tools and features. 
                        All our boilerplates are actively maintained to follow the latest technology trends.
                    </p>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic08} alt="Open-source Nodejs Starter - provided by AppSeed." />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h2>
                                    <a href="https://appseed.us/boilerplate-code/nodejs-starter">Nodejs Starter</a>
                                </h2>
                            </header>
                            <p>
                                This open-source boilerplate is a simple Nodejs / Express starter enhanced with JWT authentication (passport, passport-jwt), ES6. Other Features: SQLite database, Sequelize ORM, secure REST api.    
                            </p>
                            <ul className="actions">
                                <li>
                                    <a href="https://appseed.us/boilerplate-code/nodejs-starter" className="button">Read more</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic09} alt="Open-source Laravel boilerplate - provided by AppSeed." />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h2>
                                   <a href="https://appseed.us/boilerplate-code/laravel-boilerplate">Laravel Boilerplate</a>
                                </h2>
                            </header>
                            <p>
                                This open-source boilerplate is a simple Laravel starter enhanced with JWT authentication (passport, passport-jwt), ES6. 
                                Other Features: MySql database, and a secure REST api.    
                            </p>
                            <ul className="actions">
                                <li>
                                    <a href="https://appseed.us/boilerplate-code/laravel-boilerplate" className="button">Read more</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic10} alt="Open-source Flask boilerplate - provided by AppSeed." />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h2>
                                   <a href="https://appseed.us/boilerplate-code/flask-boilerplate">Flask Boilerplate</a>
                                </h2>
                            </header>
                            <p>
                                Our list with Flask boilerplates is quite long and popular amoung developers: Material Dashboard, Argon Dashboard, Flask Solid state. 
                                All Flask boilerplates are equipped with authentication, SQLite database and SQLAlchemy ORM.    
                            </p>
                            <ul className="actions">
                                <li>
                                    <a href="https://appseed.us/boilerplate-code/flask-boilerplate" className="button">Read more</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Landing